<template>
  <div class="service-order">
    <el-card class="box-card" shadow="never">
      <el-form slot="header" class="searchFrom square-input-warp" size="small" :inline="true"
               lable-width="80px"
               :model="orderConfig.searchForm">
        <el-form-item label="订单编号:">
          <el-input v-model="orderConfig.searchForm.orderNo" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="下单时间:">
          <el-date-picker
            v-model="orderConfig.searchForm.createTimeLimit"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-button size="small" @click="orderConfig.loadData()" type="primary">搜索</el-button>
        <el-button size="small" @click="orderConfig.resetSearchForm()">重置</el-button>
        <div class="operation-btn-group">
          <template v-for="operation in orderStatusOperation">
            <el-button
              @click="orderConfig.changeOrderStatus(operation.value)"
              plain size="mini" :key="operation.name"
              :class="[{active: activeOperation(operation.value)}]"
            >
              {{ operation.name }}
            </el-button>
          </template>
        </div>
      </el-form>
      <div>
        <el-row class="sctp-pad15">
          <el-col :span="1">
            <!--@change="setAllStatus" :indeterminate="indeterminate" :value="selectAll"-->
            <el-checkbox>全选</el-checkbox>
          </el-col>
          <el-col :span="12">
            <div class="sctp-tc">店铺宝贝</div>
          </el-col>
          <el-col :span="2">
            <div class="sctp-tc">单价</div>
          </el-col>
          <el-col :span="2">
            <div class="sctp-tc">状态</div>
          </el-col>
          <el-col :span="2">
            <div class="sctp-tc">托管金额</div>
          </el-col>
          <el-col :span="2">
            <div class="sctp-tc">操作</div>
          </el-col>
        </el-row>
        <div style="min-height: 100px;" v-loading="orderConfig.isLoadData">
          <template v-if="orderConfig.data && orderConfig.data.length > 0">
            <template v-for="(order,index) in orderConfig.data">
            <div class="order-item" :key="order.orderid">
              <div
                class="sctp-flex sctp-mar-b10 sctp-pad15 sctp-flex-aic sctp-font-12 sctp-bg-gray sctp-info">
                <div class="sctp-mar-r15">订单号：{{ order.orderno }}</div>
                <div class="sctp-mar-r15">提交时间：{{ dateToString(order.createtime) }}</div>
              </div>
              <div class="goods-list"
                   @click="orderConfig.optionsConfig.onClick('orderCycle', order, index)">
                <el-row type="flex" align="middle" class="sctp-pad15 goods-item">
                  <el-col :span="1">
                    <el-checkbox v-model="order.selected"></el-checkbox>
                  </el-col>
                  <el-col :span="12">
                    <div class="">
                      <div class="sctp-flex">
                        <el-image
                          class="sctp-mar-r15"
                          :src="order.service.servicecovers[0]"
                          style="width: 80px; height: 80px"
                          fit="cover"></el-image>
                        <div class="sctp-flex-item-1">
                          <router-link
                            target="_blank"
                            :to="`/service/view/${order.service.serviceid}`"
                          >
                            <h3>
                              {{ order.service.servicename }}
                            </h3>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="2">
                    <div class="sctp-tc">¥{{ order.service.serviceprice }}</div>
                  </el-col>
                  <el-col :span="2">
                    <div class="sctp-tc">
                      {{ status(order.status).label }}
                      <template v-if="order.status === 8">
                        原因：{{ order.refusereason }}
                      </template>
                    </div>
                  </el-col>
                  <el-col :span="2">
                    <div class="sctp-tc">
                      <template v-if="order.hostingamount">
                        {{ order.hostingamount }}元
                      </template>
                    </div>
                  </el-col>
                  <el-col :span="2">
                    <div class="options-btn-group">
                      <template v-if="order.status === 1">
                        <el-button
                          size="mini" type="primary"
                          @click.stop="orderConfig.optionsConfig.onClick('hosting', order, index)"
                        >
                          托管资金
                        </el-button>
                      </template>
                      <template v-if="order.status === 4">
                        <el-button
                          size="mini" type="primary"
                          @click.stop="orderConfig.optionsConfig.onClick('accept', order, index)"
                        >
                          验收
                        </el-button>
                      </template>
                      <template v-if="order.status === 5">
                        <el-button
                          size="mini" type="primary"
                          @click.stop="orderConfig.optionsConfig.onClick('pay', order, index)"
                        >
                          确认支付
                        </el-button>
                      </template>
                      <template v-if="order.status === 6">
                        <el-button
                          size="mini" type="primary"
                          @click.stop="orderConfig.optionsConfig.onClick('evaluate', order, index)"
                        >
                          去评价
                        </el-button>
                      </template>
                      <el-button
                        @click.stop="orderConfig.optionsConfig.onClick('details', order, index)"
                        size="mini" type=""
                      >
                        订单详情
                      </el-button>
                    </div>
                  </el-col>
                </el-row>
                <div class="pad-tb10 pad-lr15 sctp-info flex feedback">
                  <div class="flex flex-center sctp-pad-lr15">
                  <span
                    style="padding: 12px 15px;color: #ffffff;background-color: #ff9e00;">反馈内容</span>
                  </div>
                  <div class="flex-item1">
                    <div class="flex">
                      <div>期望交付时间：{{ dateToString(order.expectedDeliveryTime) }}</div>
                      <div class="sctp-mar-l20">预算费用：{{ order.budget }}元</div>
                      <div class="sctp-mar-l20">联系方式：{{ order.phone }}</div>
                    </div>
                    <div class="sctp-pad-tb10">
                      附加要求：{{ order.remark }}
                    </div>
                    <div class="flex">
                      <template v-if="order.annex">
                        <template v-for="item in order.annex.split(',')">
                          <div>{{ item.substring(item.lastIndexOf('/') + 1) }}</div>
                        </template>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          </template>
          <template v-else>
            <div class="empty-text">暂无数据</div>
          </template>
        </div>
        <div class="sctp-pad-tb20 sctp-flex sctp-flex-fe">
          <el-pagination
            :current-page="orderConfig.pageConfig.page"
            :page-size="orderConfig.pageConfig.limit"
            @size-change="orderConfig.pageConfig.handleSizeChange"
            @current-change="orderConfig.pageConfig.handleCurrentChange"
            :page-sizes="orderConfig.pageConfig.pageSizes"
            layout="prev, pager, next, total, sizes"
            :total="orderConfig.pageConfig.total">
          </el-pagination>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import {service} from "@/apis/index";

export default {
  name: "ServiceOrder",
  data() {
    return {
      orderConfig: {
        isLoadData: false,
        searchForm: {
          orderNo: null,
          createTimeLimit: null,
          status: null,
        },
        changeOrderStatus: (status) => {
          this.orderConfig.searchForm.status = status;
          this.orderConfig.loadData();
        },
        resetSearchForm: () => {
          Object.keys(this.orderConfig.searchForm).forEach(key => {
            this.orderConfig.searchForm[key] = null;
          });
        },
        activeOrderId: null,
        data: [],
        loadData: () => {
          let {orderNo, createTimeLimit, status} = this.orderConfig.searchForm;
          let {pageConfig: {page, limit}} = this.orderConfig;
          let createStart, createEnd;
          if (createTimeLimit) {
            createStart = new Date(this.dateToString(createTimeLimit[0],
              'yyyy-MM-dd 00:00:00'))
            createEnd = new Date(this.dateToString(createTimeLimit[1],
              'yyyy-MM-dd 23:59:59'))
          }
          this.orderConfig.isLoadData = true;
          service.userOrder({
            userId: this.user.userId,
            createStart: createStart,
            createEnd: createEnd,
            orderNo: orderNo,
            status: status,
            page, limit
          }).then(res => {
            let {retdata, count} = res;
            this.orderConfig.data = retdata;
            this.orderConfig.pageConfig.total = count;
          }).finally(() => {
            this.orderConfig.isLoadData = false;
          })
        },
        pageConfig: {
          page: 1,
          limit: 10,
          total: 0,
          pageSizes: [1, 2, 5, 10].map(item => item * 10),
          handleSizeChange: (limit) => {
            this.orderConfig.pageConfig.page = 1;
            this.orderConfig.pageConfig.limit = limit;
            this.orderConfig.loadData();
          },
          handleCurrentChange: (page) => {
            this.orderConfig.pageConfig.page = page;
            this.orderConfig.loadData();
          }
        },
        optionsConfig: {
          options: {
            hosting: ({item, index}) => {
              this.goPage({
                path: '/service/buy/signingContract',
                name: '服务签订合同',
                query: {
                  orderId: item.orderid
                }
              })
            },
            accept: ({item, index}) => {
              this.goPage({
                path: '/service/buy/confirmService',
                query: {
                  orderId: item.orderid
                }
              })
            },
            pay: ({item, index}) => {
              this.goPage({
                path: '/service/buy/pay',
                query: {
                  orderId: item.orderid
                }
              })
            },
            evaluate: ({item, index}) => {
              this.goPage({
                path: '/service/buy/evaluate',
                query: {
                  orderId: item.orderid
                }
              })
            },
            details: ({item, index}) => {
              this.openPage(`/order/service/orderDetail/${item.orderid}`);
            },
            end: ({item, index}) => {
              this.$confirm('确认服务完成?', '提示').then(() => {
                service.optionsService({orderId: item.orderid, status: 4}).then(() => {
                  this.orderConfig.loadData();
                })
              })
            },
            orderCycle: ({item, index}) => {
              let path = '', query = {
                orderId: item.orderid
              };
              switch (item.status) {
                case 1:
                  path = '/service/buy/signingContract';
                  break;
                case 4:
                  path = '/service/buy/confirmService';
                  break;
                case 5:
                  path = '/service/buy/pay';
                  break;
                case 6:
                  path = '/service/buy/evaluate';
                  break;
              }
              if (!path) {
                return;
              }
              this.$router.push({
                path: path,
                query: query
              });
            },
          },
          onClick: (option, item, index) => {
            this.orderConfig.activeOrderId = item.orderid;
            if (this.orderConfig.optionsConfig.options[option]) {
              this.orderConfig.optionsConfig.options[option].call(null, {item: item, index: index});
            }
          }
        },
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
    }
  },
  methods: {},
  computed: {
    orderStatusOperation() {
      return [
        {name: '全部', value: null},
        {name: '待接受', value: 0},
        {name: '待托管', value: 1},
        {name: '被拒绝', value: 8},
        {name: '待验收', value: 4},
        {name: '已打款', value: 6},
        {name: '已完成', value: 9},
      ]
    },
    activeOperation() {
      return (value) => {
        return value === this.orderConfig.searchForm.status
      }
    },
    status() {
      let status = {
        0: {label: '待服务商确认'},
        1: {label: '服务商已确认，待托管资金'},
        2: {label: '买家托管资金'},
        3: {label: '服务商同意开始服务'},
        4: {label: '服务商确认服务完成,待验收'},
        5: {label: '待买家确认收到服务.'},
        6: {label: '买家同意支付托管资金.'},
        7: {label: '订单评价'},
        8: {label: '拒绝'},
        9: {label: '订单完成'},
        10: {label: '拒绝待退款'},
      };
      return function (state) {
        if (status[state]) {
          return status[state];
        }
        return {label: ''};
      }
    }
  },
  beforeMount() {
    this.orderConfig.loadData();
  },
}
</script>

<style scoped lang="scss">
.service-order {
  & ::v-deep .goods-introduce * {
    color: #909399;
  }

  .order-item {
    border-width: 1px;
    border-color: #e5e5e5;
    border-style: solid;
    margin: 15px 0;

    .feedback {
      position: relative;

      &:before {
        position: absolute;
        content: '';
        display: block;
        height: 1px;
        background: #e5e5e5;
        top: 0;
        left: 10px;
        right: 10px;
      }
    }
  }

  .options-btn-group {
    display: flex;
    flex-direction: column;

    & .el-button + .el-button {
      margin-left: 0;
      margin-top: 5px;
    }
  }

  .el-button.active {
    color: #F90 !important;
    border-color: #F90 !important;
  }

}
</style>
